import Common from "./common.js"
import 'parsleyjs/dist/parsley.min.js'
import 'slick-carousel/slick/slick.min.js'
import 'selectize'
class Homepage extends Common {
  constructor() {
    super();
    var CI = this;
    $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 400) {
        $('.call-div').fadeIn();
      } else {
        $('.call-div').fadeOut();
      }
    });

    $(".slider").slick({
      // normal options...
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      // the magic
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      }, {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }]
    });

    $('.phone-model').hide()

    $('.repair-place').click(function(){
      $(".repair-form").attr("tabindex",-1).focus();
      $('.form-box').removeClass('disabled')

    })

    $('.repair-input').click(function(){
      $(".repair-start").attr("tabindex",-1).focus();
      $('.repair-start').removeClass('disabled')
    })

    $('.repair-section, .service-section, .form-section').click(function(){
      if($(this).find('.disabled').length !== 0){
        $(this).find('.pervious-step-completion').addClass("visibile")
        setTimeout(function() { $('.pervious-step-completion').removeClass("visibile")}, 2000);
      }
    })

    $('.phone-model').on('change', function(){
      // $("#phone-issue").attr("tabindex",-1).focus();
      // $('.repair-type').removeClass('disabled')
      let name = $(".phone-model :selected").val()
      const urlParams = new URLSearchParams(window.location.search);
      const source = urlParams.get('source');
      const referrer_url = document.referrer
      const shop_url = window.location
      var url_string = getRedirectLink() + '/appointment?device-model-id='+name+'&alert=false&referrer_url='+referrer_url+'&shop_url='+shop_url
      if(source) {
        url_string = url_string + '&source=' + source
      }
      window.location.href = url_string
      setTimeout(function(){
        $(".phone-model").val('');
        $(".phone-brand").val('');
      }, 300)
    })

    $('.next-btn').click(function(){
      $(".repair-start").attr("tabindex",-1).focus();
      $('.repair-start').removeClass('disabled')
    });

    $('#phone-brand').on('change',function () {
        var value = $(".phone-brand :selected").val()
        if(!value) {
          return;
        }
        $('.phone-model-wrapper').show()
        var $select = $('#phone-model').selectize({
          options: [],
          onInitialize: function() {
            $('.selectize-dropdown-content').on('mousewheel DOMMouseScroll', function (e) {
              var e0 = e.originalEvent;
              var delta = e0.wheelDelta || -e0.detail;

              this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
              e.preventDefault();
            });
          },
        });
        var selectize = $select[0].selectize;
        selectize.clearOptions();
        $('.selectize-control.phone-model').addClass('loading')
        $.ajax({
          type: "GET",
          url: getApiLink() + `/api/brand_models.json?store_id=1&brand_id=${value}`,
          dataType: "json",
          crossDomain: true,
          success: function(data) {
            console.log(data)
            var options=data["brand_models"]
            for (let i=0; i< options.length; i++) {
              selectize.addOption({value:options[i].id,text:options[i].name});
            }
            $('.selectize-control.phone-model').removeClass('loading')
            // selectize.refreshOptions()
          },
          error: function(error){
            $('.selectize-control.phone-model').removeClass('loading')
            console.log(error)
          },
        })
    });

    $('.back-btn').click(function(){
      var $btn = $(this),
      $step = $btn.parents('.modal-body'),
      stepIndex = $step.index()
      var $pag = $('.modal-header span').eq(stepIndex);
      CI.backStep($step, $pag, 1)
    });



    $('.btn-next').click(function(){
      var $btn = $(this),
      $step = $btn.parents('.modal-body'),
      stepIndex = $step.index(),
      $pag = $('.modal-header span').eq(stepIndex);
      CI.nextStep($step, $pag, 1);
    });

    $(document).ready(function() {

      var $select = $('#phone-brand').selectize({
        options: [],
        onInitialize: function() {
          $('.phone-model').hide();
          $('.selectize-dropdown-content').on('mousewheel DOMMouseScroll', function (e) {
            var e0 = e.originalEvent;
            var delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
            e.preventDefault();
          });
        },
      });
      var selectize = $select[0].selectize;
      selectize.clearOptions();
      $('.selectize-control.phone-brand').addClass('loading')
      $.ajax({
        type: "GET",
        url: getApiLink() + `/api/brands.json?store_id=1`,
        dataType: "json",
        crossDomain: true,
        success: function(data) {
          console.log(data)
          var options=data["brands"]
          for (let i=0; i< options.length; i++) {
            selectize.addOption({value:options[i].id,text:options[i].name});
          }
          $('.selectize-control.phone-brand').removeClass('loading')
        },
        error: function(error){
          $('.selectize-control.phone-brand').removeClass('loading')
          console.log(error)
        },
      })
    });

    const getApiLink = () => {
      return document.querySelector('meta[name="api_link"]').content
    }
    const getRedirectLink = () => {
      return document.querySelector('meta[name="redirect_link"]').content
    }
  }
  repairOptions() {
    this.repairOption = $("input[name='repair-option']:checked").val()
  }
}
export default new Homepage();
