import 'bootstrap/dist/js/bootstrap.js'
import 'parsleyjs/dist/parsley.min.js';

class Common {
  constructor() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    if(url.searchParams.get('created')){
      if(url.searchParams.get('created') == 'true'){
        window.location.href ="/success"
      }
    }
    var CI = this;
    this.formValidation = {}
    this.validate();
    this.currentTab = 0;
    this.repairOption = ""
    this.userId= null
    CI.step = 0
  }

  validate(){
    this.formValidation = $('.repair-form').parsley({
      trigger: "focusout",
      errorClass: 'error',
      successClass: 'valid',
      errorsWrapper: '<div class="parsley-errors-list"></div>',
      errorTemplate: '<label class="error"></label>',
      errorsContainer (field) {
        if(field.$element.hasClass('approve')){
          return $('.error-checkbox')
        }
        if(field.$element.hasClass('error-on-button')){
          return $(field.element.closest(".steps").querySelector(".error-box"))
        }
        return field.$element.parent() 
      },
    })
    this.validatePostcode()
  }

  validatePostcode(){
    window.Parsley.addValidator('valid-postcode', {
      validateString: function(value){
       return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i.test(value);
      },
      messages: {
         en: 'Please Enter Valid UK Postcode',
      }
    });
  }

  showTab(n=0) {
    var tabs = $(".tab");
    if (!tabs[n]) return;
    tabs[n].style.display = "block";
    $(".tab").removeClass("in-progress")
  }

  backStep($step, $pag, n){
    if ($step.index() > 0) {
      setTimeout(function(){
        $step.removeClass('animate-out is-showing')
        .prev().addClass('animate-in');
        $pag.removeClass('is-active')
        .prev().addClass('is-active');
      }, 600);
      setTimeout(function(){
        $step.prev().removeClass('animate-in')
        .addClass('is-showing');
      }, 1200);
    }
  }

  showCircle(){
    $(".tab").addClass("in-progress")
  }

  hideCircle(){
    $(".tab").removeClass("in-progress")
  }

  animate($step, $pag){
    $step.addClass('animate-out');
    // animate the step in
    setTimeout(function(){
      $step.removeClass('animate-out is-showing d-flex')
      .next().addClass('animate-in');
      $pag.removeClass('is-active')
      .next().addClass('is-active');
    }, 600);
    // after the animation, adjust the classes
    setTimeout(function(){
      $step.next().removeClass('animate-in')
      .addClass('is-showing');
    }, 1200);
  }

  nextStep($step, $pag, n) {
    var CI = this;
    $('.repair-form').parsley().whenValidate({
      group: 'block-' + $step.index()
    }).done(() =>{
      var tabs = $(".tab");
      if ($step.index() == 0) {
        CI.repairOptions()
        CI.postData();
        CI.animate($step, $pag)
      }else{
        CI.repairOptions()
        CI.updateData();
        CI.animate($step, $pag)
      }
    })
  } 

  getData() {
    return {
      repairplace: $("input[name='repair-place']:checked").val() || '',
      phonebrand: $(".phone-brand :selected").val() || '',
      phonemodel: $(".phone-model :selected").val() || '',
      phoneproblem: this.repairOption || '',
      firstname:  $(".first_name").val() || '',
      lastname: $(".last_name").val() ||  '',
      email: $(".email").val() ||  '',
      phone: $(".phone").val() ||  '',
      postcode: $(".postcode").val() ||  '',
      address: $(".address").val() ||  '',
      referrer: document.referrer,
      source: this.getUrlParameter("source"),
    };
  }

  postData() {
    this.showCircle()
    var CI = this;
    var data = this.getData();
    this.submitLead(data)
  }

  updateData() {
    this.showCircle()
    var CI = this;
    var data = this.getData();
    this.updateLead(data)
  }

  submitLead(formData){
    var CI = this
    $.ajax({
      type: "POST",
      url: `/pages`,
      data: formData,
      success: function(data) {
        console.log(data)
        CI.userId = data.user.id
        CI.hideCircle()
      },
      error: function(error){
        console.log(error)
      },
      dataType: "json"
    })
  }

  updateLead(formData){
    var CI = this
    $.ajax({
      type: "PUT",
      url: `/pages/${this.userId}`,
      data: formData,
      success: function(data) {
        CI.step =  CI.step + 1
        console.log(data)
        CI.hideCircle()
        if(CI.step == 2 || $("input[name='repair-place']:checked").val() == "On-Shop-Repair"){
          window.location.href ="/success"
        }
      },
      error: function(error){
        console.log(error)
      },
      dataType: "json"
    })
  }

  getFormattedCurrentDate() {
    var date = new Date();
    var day = this.addZero(date.getDate());
    var monthIndex = this.addZero(date.getMonth() + 1);
    var year = date.getFullYear();
    var min = this.addZero(date.getMinutes());
    var hr = this.addZero(date.getHours());
    var ss = this.addZero(date.getSeconds());

    return day + '/' + monthIndex + '/' + year + ' ' + hr + ':' + min + ':' + ss;
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }
}

export default Common;
